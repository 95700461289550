import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy;2018 - 2019 uminek.ooo <br /> Gatsby Starter -  <a href="https://github.com/codebushi/gatsby-starter-dimension" target="_blank" rel="noopener noreferrer">Dimension.</a> Design: <a href="https://html5up.net" target="_blank" rel="noopener noreferrer">HTML5 UP</a>. Built with: <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">Gatsby.js</a>
<br />
<a href="https://uminek.ooo/policy" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>
 
</p>
   </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
