import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="m78 742.5c1.4-57.3 10.7-115.9 27.2-172.2 51.5-175.5 169.5-325.1 328.5-416.4 25.9-14.9 68.2-35.9 70.7-35.1 0.5 0.2-4.9 5.5-11.9 11.7-17.9 15.9-55.5 53.1-74.2 73.4-98.1 106.1-176 229.4-229.5 363.5-23.5 58.8-43.6 123.6-56.1 181.2-1.7 8-3.4 15.3-3.6 16.2-0.5 1.6-2.6 1.7-26 1.7h-25.6z" fill="#fff"/><path d="m168.6 766.2c0-2 4.6-28.7 7.1-41.4 31.5-160 114.8-302.6 236-404.2 25.9-21.7 57.3-44 84.5-60.1 6.9-4.1 14-8.3 15.9-9.4 3.3-2 3.4-2 2.7-0.1-0.4 1.1-4.1 11.8-8.2 23.9-33.2 97.7-51.9 193.2-59.3 303.1-2.9 43.7-2.2 117.8 1.6 166.8 0.8 10.2 1.4 19.3 1.4 20.2 0 1.7-7.4 1.7-140.8 1.7-77.4 0-140.8-0.2-140.8-0.5z" fill="#fff"/><path d="m517.7 744c0.3-12.5 4.3-183 8.8-378.9 4.5-195.9 8.5-356.5 8.9-356.9 1.6-1.6 49.8 22.2 75.1 37 31 18.2 70 45.4 94.9 66.3 33.5 28.2 67.7 62.6 93.9 94.5 80.4 98 132.6 214.8 151.6 339 5.5 36.2 7.7 65 7.7 104.4 0 39.5-1.8 65.7-7.1 102.4-1.1 7.4-2 13.8-2 14.2 0 0.4-97.3 0.7-216.2 0.7h-216.2z" fill="#fff"/></svg>
        </div>
        <div className="content">
            <div className="inner">
                <h1>uminek.ooo/うみねこ</h1>
                        <p> Virtual Yacht Sailor</p>
			<p>YouTube, niconico でセーリングに関する動画を投稿します!</p>
            </div>
        </div>
        <nav>
            <ul>
                        <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>About</a></li>
	    </ul>
        </nav>
        <nav>
            <ul>
                        <li><a href="https://www.youtube.com/channel/UCjE_66bp9D6dSHwdxuBmCNQ" target="_blank" rel="noopener noreferrer"><u>YouTube</u></a></li>
                        <li><a href="https://www.nicovideo.jp/mylist/64196769" target="_blank" rel="noopener noreferrer"><u>niconico</u></a></li>
                        <li><a href="https://twitter.com/uminek_ooo" target="_blank" rel="noopener noreferrer"><u>Twitter</u></a></li>
                        <li><a href="https://blog.uminek.ooo" target="_blank" rel="noopener noreferrer"><u>Blog</u></a></li>
                        <li><a href="https://marshmallow-qa.com/uminek_ooo" target="_blank" rel="noopener noreferrer"><u>質問箱</u></a></li>
	    </ul>
       </nav>
       <nav>
            <ul>
                        <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>            
	    </ul>
       </nav>
</header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
