import React from 'react'
import PropTypes from 'prop-types'
import AniLink from "gatsby-plugin-transition-link/AniLink"

const News = (props) => (
    <news id="news" style={props.timeout ? {display: 'none'} : {}}>

        <h2>NEWS</h2>
            <AniLink fade to="20181230" color="black">
              2018/12/30 　自己紹介動画を投稿し、活動を開始しました！
            </AniLink>
<br />
            <AniLink fade to="20180930" color="black">
              2018/09/30 　PVが完成しました！
            </AniLink>
<br />
            <AniLink fade to="20180901" color="black">
              2018/09/01 　公式サイトが完成しました！
            </AniLink>

    </news>
)

News.propTypes = {
    timeout: PropTypes.bool
}

export default News
