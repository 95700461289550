import React from 'react'
import PropTypes from 'prop-types'
import uminek_ooo00 from '../images/uminek.ooo_00.jpg'
import uminek_ooo01 from '../images/uminek.ooo_01.jpg'
import uminek_ooo02 from '../images/uminek.ooo_02.jpg'

const Videos = (props) => (
		<videos id="videos" style={props.timeout ? {display: 'none'} : {}}>
		<h2>VIDEOS</h2>
		<nav>
		<ul>
		<li>
		<iframe src="https://player.twitch.tv/?autoplay=false&channel=uminek_ooo" frameborder="0" allowfullscreen="true" scrolling="no"></iframe>
		</li>
		</ul>
		</nav>
		<nav>
		<ul>
		<li><a href="https://www.youtube.com/watch?v=Afa5mpdoJI8" target="_blank" rel="noopener noreferrer"><img src={uminek_ooo02} alt="uminek.ooo_movie02"/></a></li>
		<li><a href="https://www.youtube.com/watch?v=yKdSWuYvbaQ" target="_blank" rel="noopener noreferrer"><img src={uminek_ooo01} alt="uminek.ooo_movie01"/></a></li>
		<li><a href="https://youtu.be/MTayM2lkcrI" target="_blank" rel="noopener noreferrer"><img src={uminek_ooo00} alt="uminek.ooo_movie00"/></a></li>
		</ul>
		</nav>
		</videos>
		)

Videos.propTypes = {
    timeout: PropTypes.bool
}

export default Videos
